<template>
  <div class="form-group">
    <label for="brand" class="d-block">{{ option.name }}</label>
    <div>
      <input class="form-control" v-model="selectedOption" @input="selectOption" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Input',
  props: ['initValue', 'option', 'value'],
  data() {
    return {
      selectedOption: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.selectedOption = this.value;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.initValue) {
      const dictionary = this.initValue.find((item) => item.product_option.id === this.option.id);

      if (dictionary) {
        this.selectedOption = dictionary.dictionary.name;
        this.$emit('refresh', this.selectedOption, this.option.id);
      }
    }
  },
  methods: {
    async selectOption() {
      this.$emit('refresh', this.selectedOption, this.option.id);
    },
  },
};
</script>

<style scoped>

</style>
