<template>
  <div class="row content-header">
    <div class="col-12 col-md-9 content-header-left mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12">
          <h2 class="content-header-title float-left pr-1 mb-0">{{ $route.meta.pageTitle }}</h2>
          <div class="breadcrumb-wrapper" v-if="breadcrumbs.length">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to" >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'AppBreadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
};
</script>
