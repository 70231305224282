<template>
  <div class="form-group">
    <label for="brand" class="d-block">{{ option.name }}</label>
    <div>
      <v-select
        v-model="selectedOption"
        :clearable="false"
        :options="options"
        label="name"
        @input="selectOption"
      />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'Select',
  components: { vSelect },
  props: ['option', 'initValues'],
  data() {
    return {
      selectedOption: null,
      options: [],
    };
  },
  mounted() {
    this.options = this.option.values;
    this.setDefaults();
  },
  methods: {
    async selectOption() {
      if (this.selectedOption && this.selectedOption.id === 'all') {
        this.selectedOption = null;
        this.options = (await this.$api.options.values(this.option.id)).data;
      } else {
        this.$emit('refresh', this.selectedOption, this.option.id);
      }
    },
    setDefaults() {
      if (this.initValues && this.initValues.length > 0) {
        const dictionary = this.initValues.find((item) => item.product_option_id === this.option.id);

        if (dictionary) {
          this.selectedOption = this.option.values.find((item) => item.id === dictionary.dictionary_id);
        }
      } else {
        if (this.option.values.length === 2) {
          this.selectedOption = this.option.values.find((item) => item.id !== 'all');
        }

        if (this.option.values.length > 2) {
          switch (this.option.id) {
            case 12:
              this.selectedOption = this.option.values.find((brand) => brand.id === 11) || null;
              break;
            case 15:
              this.selectedOption = this.option.values.find((country) => country.id === 13);
              break;
            default:
              break;
          }
        }
      }

      this.$emit('refresh', this.selectedOption, this.option.id);
    },
  },
};
</script>

<style scoped>

</style>
